@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.q-menu {
    max-height: 100vh;
}

span.block {
    &#name {
        display: block !important;
        line-height: 22px;
        max-height: 44px;
        overflow: hidden;
        white-space: pre-line;
        max-width: 380px;
        text-overflow: ellipsis;
        position: relative;
    }

    &#mnn {
        display: block !important;
        line-height: 22px;
        height: 22px;
        overflow: hidden;
        white-space: pre-line;
        max-width: 108px;
        text-overflow: ellipsis;
        position: relative;
    }
}

.custom-sale {
    width: fit-content;
}

.q-field {
    width: 100%;
}

.description {
    position: absolute;
    bottom: -39px;
    left: 53px;
    font-size: 12px;

    @media (max-width: 1280px) {
        font-size: 14px;
        left: 50px;
        bottom: -38px;
    }

    @media (max-width: 960px) {
        bottom: -36px;
        a:nth-child(n+2):nth-child(-n+3) {
            display: none;
        }
    }
}

.q-field :deep(.q-field__control) {
    border: 2px solid $positive;
    border-radius: 20px;
    background: #fbfafa !important;
    padding-left: 1em;
    padding-right: 0;
    height: 44px;
    overflow: hidden;

    // @media (max-width: 1280px) {
    //     padding-left: 10px;
    //     padding-right: 10px;
    // }

    &::before {
        background: transparent;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        height: 38px;
        border-radius: 10px;
        border: none;
        background: $grey-17 !important;
    }
}

.q-field :deep(.q-field__append) {
    cursor: pointer;

    &:nth-of-type(2) {
        background: $app-button-gradient;
        border-radius: $button-border-radius;
        width: 135px;
        height: 100%;
        padding-left: 0.7em;
        position: relative;
        left: 2px;
    }

    &:nth-of-type(3) {
        position: absolute;
        right: 24px;
        height: 100%;
        color: $positive;
        background: $secondary !important;

        @media screen and (max-width: $breakpoint-mobile) {
            background: $grey-17 !important;
        }
    }
}

.q-field :deep(.q-field__native) {
    font-size: 16px;
    font-weight: 500;
    padding: 0 0 4px 0;

    @media(max-width: 1280px) {
        padding: 2px 0 2px 11px;
    }

    @media(max-width: 960px) {
        font-size: 14px;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        background-color: $grey-17 !important;
    }

    &::placeholder {
        color: $grey-12;
    }
}

.q-field.q-field--focused :deep(.q-field__control) {
    box-shadow: none;
    background: rgba($secondary, 1) !important;

    &::before {
        background: transparent;
    }

    input.q-field__native {
        color: $primary !important;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        background: $grey-17 !important;
    }
}

.q-field :deep(.q-field__prepend) {
    height: 100%;

    @media(max-width: 1280px) {
        padding: 0
    }
}

.q-field :deep(.q-field__append:nth-of-type(3)) {
    right: 0px;
    z-index: 999;
}

.search-list {
    list-style: none;
    box-sizing: border-box;

    & li {
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;

        &.not-hover {
            border-bottom: 1px solid $grey-16;
        }

        &:hover:not(.not-hover) {
            background-color: $grey-3;
            border-top: 1px solid $grey-17;
            border-bottom: 1px solid $grey-17;
        }
    }

    &__focused {
        background-color: $grey-3;
        border-top: 1px solid $grey-17 !important;
        border-bottom: 1px solid $grey-17 !important;
    }

    &__img {
        width: 75px;
        height: 75px;

        & > img {
            max-width: 75px;
            max-height: 75px;
        }
    }
}

.clear-search {
    right: 110px;
    top: 50%;
    transform: translateY(-52%);
}

.q-scroll-area {
    @media screen and (max-width: $breakpoint-mobile) {
        z-index: 1999;
    }
}
