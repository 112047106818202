@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.q-card {
    background: $app-color-background;

    .q-card-section__wrapper {
        height: 400px;
        background: $secondary;
        border-radius: $generic-border-radius;

        .q-scrollarea {
            height: 320px;
        }

        .q-list .q-item {
            border-radius: $generic-border-radius;
        }
    }
}

.q-card::-webkit-scrollbar {
    display: none;
}

.text {
    &__title {
        @media (max-width: 1280px) {
            font-size: 20px !important;
        }
    }
}
