@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.desktop-header {
    position: relative !important;
    background-color: #fbfafa !important;

    .border-radius-16 {
        border-radius: 30px;
    }

    .q-toolbar {

        min-height: 64px;

        @media (max-width: 1280px) {
            min-height: 44px;
        }
        @media (max-width: 960px) {
            min-height: 38px;
        }

        a {
            text-decoration: none;
        }
    }

    .first-header {
        &__link {
            @media screen and (min-width: 1280px) {
                padding: 0 8px;
            }

            @media screen and (min-width: 1440px) {
                padding: 0 16px;
            }
        }

        &__last-link {
            @media screen and (min-width: 1280px) {
                padding-left: 16px;
            }
        }
    }

    .second-header {
        font-weight: bold;

        a {
            color: $app-text-secondary;
            display: block;
        }
    }


    .third-header {
        a {
            color: $app-text-accent;
        }


        .wrapper {
            @media (max-width: 960px) {
                align-items: flex-end;
            }
        }

        &__fix {
            position: fixed;
            top: 0;
            height: 118px;
        }

        &__fix-border {
            border-bottom: 1px solid $grey-7;
        }

        &__icons {
            & > i {
                margin-bottom: 3px;
            }
        }

        &__search {
            @media (max-width: 1280px) {
                margin-left: 0px;
                margin-right: 0px;
            }
        }

        &__avatar_link {
            display: block;

            @media screen and (max-width: 960px) {
                margin: 0;
            }
        }
    }
}

.active-offers :deep {
    height: 33px !important;
    border-radius: 40px;

    .q-btn__wrapper {
        display: flex;
        align-items: center;
        padding: 0 16px !important;
        min-height: 100% !important;

        &::before {
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2) !important;
        }
    }

    .q-btn__content {
        text-transform: none !important;

        span {
            font-weight: 400;
            line-height: 22px;
        }

        i {
            margin-right: 15px;
        }
    }
}

.mobile-header {
    background-color: inherit;

    .q-toolbar {
        margin-top: 8px;
        background: none;
        backdrop-filter: blur(27.1828px) !important;
        border-radius: 0px 0px 0px 24px;

        @media screen and (max-width: $breakpoint-mobile) {
            backdrop-filter: none !important;
        }
    }
}

.q-avatar {
    width: 113px;
    height: 55px;

    @media (max-width: 960px) {
        width: 40px;
        height: 40px;
        margin: 0;
    }
}

.q-avatar :deep(.q-avatar__content) {
    overflow: unset !important;
}

.q-badge--floating {
    top: -9px;
    right: -5px;
}

.q-menu {
    box-shadow: none !important;
}

.hide_tablet {
    @media (max-width: 1280px) {
        display: none;
    }
}

.logo-text {
    font-weight: 700;
    position: relative;
    bottom: 5px;
    left: 2px;
}

.space {
    @media screen and (max-width: 1280px) {
        display: none;
    }
}

.heart :deep {
    div {
        i {
            animation: heartbeat 1s infinite;
        }
    }
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1.1) translateX(5%) translateY(5%);
    }
    40% {
        transform: scale(1.2) translateX(9%) translateY(10%);
    }
}

.q-carousel {
    height: initial;
    background-color: transparent;

    .q-carousel__slide {
        padding: 5px 0 0;

        img {
            border-radius: 30px;
        }
    }
}

.top {
    height: 68px;
}

.top-menu {
    border: 1px solid transparent;
}

.top-menu.router-link-exact-active {
    border: 1px solid #fff;
    border-radius: 30px;
}

.btn-call :deep(.btn__label) {
    padding-left: 8px;
    width: 82%;
}

.btn-call :deep(.col-sm-3) {
    width: 18%;
}

.btn-call :deep(.col-12) {
    align-self: baseline;
}

.address-card {
    width: 500px;
    height: 190px;
    top: 124px;
    position: fixed;
    z-index: 9999;
}

.modal-map {
    &__body {
        max-width: 100% !important;
        width: 90vw !important;
        height: 90vh !important;

        @media screen and (max-width: $breakpoint-mobile) {
            width: 100vw !important;
            height: 89vh !important;
        }
    }
}

.close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
}
