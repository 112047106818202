@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.dialog {
    width: 990px;
    max-width: 990px !important;
}

.suggests {
    position: fixed;
    z-index: 1;
    width: 744px;
}

.address {
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.10);
}

.address-field {
    width: calc(50% - 9px);
}

.button-block {
    position: fixed;
    width: 100%;
    bottom: constant(safe-area-inset-bottom) !important;
    bottom: env(safe-area-inset-bottom) !important;
    border-radius: $mobile-border-radius $mobile-border-radius 0 0;
    box-shadow: -2px -10px 16px -8px rgba(0, 0, 0, 0.15);
    z-index: 100;
    padding: 10px 16px;
}

.suggestions {
    position: fixed;
    z-index: 1;
    width: calc(100% - 32px);
}

.error {
    .q-field__control {
        border-radius: 8px !important;
        background: $secondary !important;
        border: 1px solid !important;

        &:hover:before {
            border-color: $negative !important;
        }
    }
}

.cart-shipping-input {
  padding: 0;

  &__textarea {
      border-radius: 8px !important;
      height: 110px;
      background: $secondary !important;
      border: 1px solid $grey-16 !important;
      outline: none;

      line-height: 16px;
      padding: 15px 22px;
      color: $app-text-primaty;

      font: {
          size: 16px;
          weight: 400;
      }

      @media screen and (max-width: $breakpoint-mobile) {
          font-size: 14px;
      }

      @media screen and (min-width: $breakpoint-mobile) {
          margin-bottom: 0 !important;
      }

      &::placeholder {
          color: $gray !important;
      }
  }

  .q-field__control {
      border-radius: 8px !important;
      background: $secondary !important;

      &:hover:before {
        border-color: $grey-16 !important;
      }
  }

  .q-field__bottom {
      padding: 4px 0 0;
  }

  .q-field__native {
      line-height: 16px;
      padding: 5px 10px 5px 3px;
      color: $app-text-primaty;

      font: {
          size: 16px;
          weight: 400;
      }

      @media sreen and (max-width: $breakpoint-mobile) {
          font-size: 12px;
      }

      &::placeholder {
          color: $gray !important;
      }
  }

  .q-field__append {
      height: 100% !important;
      color: $medium-gray;

      .q-icon {
          font-size: 16px !important;
      }
  }

  &__apartment, &__entrance {
      .q-field__control {
          @media screen and (max-width: $breakpoint-mobile) {
              border-radius: 8px 0 0 8px !important;
          }
      }
  }
  &__floor, &__intercom {
      .q-field__control {
          @media screen and (max-width: $breakpoint-mobile) {
              border-radius: 0 8px 8px 0 !important;
          }
      }
  }
}

.roundborder {
    border-radius: 16px 16px 0 0 !important;
}

.another :deep(.q-field__control) {
    padding: 0 24px;
}
