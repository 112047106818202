@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.drugstore {
    color: $primary;
    margin: 0 10px 10px;
    border-radius: $mobile-border-radius;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    background: white;

    @media (max-width: 960px) {
        padding: 14px 24px;
    }
    @media screen and (max-width: $breakpoint-mobile) {
        padding: 0 4px;
    }

    &-active {
        color: $positive;
    }

    &__address {
        @media (max-width: 1280px) {
            font-size: 14px !important;
        }
        @media screen and (max-width: $breakpoint-mobile) {
            font-weight: 600;
        }
    }
    &__city {
        @media (max-width: 960px) {
            font-size: 14px !important;
        }
    }
    &__schedule {
        @media (max-width: 960px) {
            font-size: 12px !important;
        }
    }
}

:deep .q-expansion-item .q-item {
    font-size: 14px;
    min-height: 0;
    padding: 4px 0;

    .q-focus-helper {
        display: none;
    }

    &__section--side {
        color: v-bind('expansionColor');
    }
}
