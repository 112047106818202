@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.q-field.q-field--disabled :deep(.q-field__control > div) {
    opacity: 1 !important;
}

.q-field :deep(.q-field__control) {
    height: 44px;
    box-shadow: none;
    border: 1px solid $grey-13;

    &::before {
        background: transparent;
    }

    .q-field__label {
        color: $grey-1;
        font-size: 1.1em;
        line-height: 1.6em;
    }

    ::placeholder {
        color: $grey-12;
    }
}

.q-field :deep(.q-field__bottom) {
    padding-top: 2px;
}

.q-field.q-field--focused :deep(.q-field__control) {
    border: 1px solid $positive;

    .q-field__native {
        color: $primary !important;
    }

    .q-field__append,
    .q-field__prepend {
        color: $grey-4;
    }
}

.q-field.q-field--error :deep(.q-field__control) {
    border: 1px solid $negative !important;

    .q-field__label,
    .q-field__native {
        color: $negative;
    }
}

.q-field--with-bottom {
    padding: 0;
}

.q-field.q-field--disabled :deep(.q-field__control) {
    .q-field__append,
    .q-field__prepend {
        height: 100%;
    }

    .q-field__native {
        color: $grey-1;
    }
}

.q-field.q-field--rounded :deep(.q-field__control),
.q-field.q-field--rounded.q-field--error :deep(.q-field__control) {
    border: 1px solid $grey-6;

    ::placeholder {
        color: $grey-13;
    }
}

.q-field.border-none :deep(.q-field__control),
.q-field.border-none.q-field--focused :deep(.q-field__control) {
    border: 1px solid $grey-3;
}

.q-field.filled :deep(.q-field__control),
.q-field.filled.q-field--focused :deep(.q-field__control),
.q-field.filled.q-field--error :deep(.q-field__control) {
    border: none;
}

.q-field.filled :deep(.q-field__control),
.q-field.filled.q-field--error :deep(.q-field__control ) {
    border-radius: $generic-border-radius;
    border-bottom: 1px solid $grey-2;

    ::placeholder {
        color: $grey-13;
    }
}

.q-field.filled.q-field--focused :deep(.q-field__control) {
    border-bottom: 1px solid $grey-2;

    ::placeholder {
        color: $secondary;
    }
}
