@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.y-map {
    position: relative;
    height: 100%;
    border: 1px solid $grey-8;
    border-radius: $button-border-radius;
    overflow: hidden;

    .q-badge {
        border-radius: 5px;
    }

    .q-checkbox {
        & .q-checkbox__bg {
            background-color: #fff;
            border: 1px solid $grey-8;
        }

        &[aria-checked="true"] .q-checkbox__bg {
            border: 2px solid $positive;
        }

        & svg {
            color: $green-14 !important;
            width: 70% !important;
            height: 70% !important;
            top: 15%;
            left: 15%;
        }

        & .q-checkbox__truthy {
            stroke-width: 6px;
        }
    }

    &__spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__wrapper {
        height: 100%;

        & > div {
            height: inherit !important;
        }
    }

    &__search {
        left: 20px;
        top: 20px;
        z-index: 2;
        min-width: 50%;
        max-width: 60%;
        position: absolute;
        outline: none;
        border-radius: 10px;
        border: 2px solid $positive;
        border-radius: 20px;

        &:focus {
            border: 2px solid $positive;
        }
    }

    &__container {
        width: 100%;
        height: 100%;
        border-left: 1px solid $grey-8;
        overflow: hidden;

        & .ymaps-2-1-78-balloon,
        & .ymaps-2-1-78-balloon > :first-child {
            border-radius: 16px;

            & .ymaps-2-1-78-balloon__close .ymaps-2-1-78-balloon__close-button {
                margin: 0 5px;
            }
        }

        .ymaps-2-1-78-balloon__content {
            min-width: 200px;
            padding: 14px;

            .q-badge {
                margin-bottom: 6px;
                border-radius: 5px;
            }

            .q-btn {
                width: 100%;
                height: 35px;

                & .q-btn__wrapper {
                    height: inherit;
                    padding: 0;

                    & .q-btn__content {
                        height: inherit;
                    }
                }
            }
        }
    }

    &__available {
        @media (max-width: 960px) {
            margin-left: 16px;
            margin-top: 0px;
        }
        @media screen and (max-width: 960px) {
            margin: 0;
        }
    }

    .ymaps-2-1-78-search__suggest {
        top: 6px;
        border-radius: $button-border-radius;
        width: 106%;
        left: -3%;
    }
}

ymaps[class*="search__suggest-item_selected_yes"] {
    background: $positive;
    color: $secondary;
}

ymaps[class*="search__suggest"] {
    left: 0 !important;
}

ymaps[class*="balloon-content__footer"] {
    box-sizing: border-box !important;
}

.q-scrollarea__bar--v {
    right: 5px !important;
    border: 1px solid $app-color-gray !important;

    @media screen and (max-width: $breakpoint-mobile) {
        display: none;
    }
}

.q-scrollarea__thumb--v {
    right: 5px !important;
    border: 3px solid $grey-10;

    @media screen and (max-width: $breakpoint-mobile) {
        display: none;
    }
}

.q-radio :deep(.q-radio__label) {
    padding-left: 16px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
}

.input-icon {
    position: absolute;
}

.input-search {
    width: 100%;
    border-radius: 20px;
    border: none;
    padding-left: 50px;

    &:focus {
        border: none;
    }
}

textarea:focus, input:focus {
    outline: none;
}

:deep(.blob) {
    border-radius: 50%;
    height: 14px;
    width: 14px;
    top: -7px;
    left: -7px;
    z-index: 9999;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
    }

    70% {
        transform: scale(1.3);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.y-map {
    position: relative;
    height: 100%;
    border: 1px solid #0000001f;
    border-radius: $button-border-radius;
    overflow: hidden;

    .y-map__wrapper {
        height: 100%;
    }
}

.mobile-map {
    background-color: $secondary;
}

.map-buttons {
    width: 50%;
}

.drugstore-list-block {
    padding-right: 4px;
}

.y-map {
    position: relative;
    height: 100%;
    border: 1px solid $grey-8;
    border-radius: $button-border-radius;
    overflow: hidden;

    &__search {
        width: 300px;
        outline: none;
        border-radius: 30px;
        box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
        border: 1px solid $grey-13;

        &:focus {
            border: 1px solid $positive;
        }

        @media screen and (max-width: 320px) {
            max-width: 160px;
        }
    }

    &__container {
        width: 100%;
        height: 100%;
        border-left: 1px solid $grey-8;
        overflow: hidden;

        & .ymaps-2-1-78-balloon,
        & .ymaps-2-1-78-balloon > :first-child {
            border-radius: $button-border-radius;

            & .ymaps-2-1-78-balloon__close .ymaps-2-1-78-balloon__close-button {
                margin: 0 5px;
            }
        }
    }
}

:deep(ymaps[class*="search__suggest"]) {
    top: 5px;
    border-radius: $button-border-radius;
    width: 100%;
    font-size: 14px;
}

:deep(ymaps[class*="search__suggest-item_selected_yes"]) {
    background-color: $positive;
    color: $secondary;
    border-radius: 0;
}

:deep(.placemark_layout_container) {
    position: relative;
}

:deep(.polygon_layout) {
    position: relative;
    width: fit-content;
    z-index: 99;
}

:deep(.blob) {
    border-radius: 50%;
    height: 14px;
    width: 14px;
    top: -7px;
    left: -7px;
    z-index: 9999;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
    }

    70% {
        transform: scale(1.3);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.dropdown-icon {
    position: absolute;
    bottom: 32%;
    left: 20px;
}

// .q-checkbox:first-child :deep(.q-checkbox__label) {
//     color: $positive;
// }
.q-checkbox :deep(.q-checkbox__label) {
    font-size: 16px;
    color: $grey-9;
}

.q-checkbox :deep(.q-checkbox__inner) {
    color: $grey-9;
}

.sorts {
    @media screen and (max-width: 1280px) {
        width: 100%;
    }
}

:deep(.q-btn-dropdown) {
    background: #fff !important;
    border: none;
}

:deep(.q-btn--outline:before) {
    border: 0 !important;
    box-shadow: 2px 1px 5px #0000000D;
}

.top {
    z-index: 9999;
}
