@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.city-form-mobile {
    border-radius: $mobile-border-radius $mobile-border-radius 0 0 !important;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
    padding-bottom: calc(16px + constant(safe-area-inset-bottom));
    padding-bottom: calc(16px + env(safe-area-inset-bottom));

    &__sub {
        height: 80vh;
    }
}

.close-button {
    width: calc(50% - 8px) !important;
}
