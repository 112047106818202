@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.active {
    border-right: 2px solid $positive;
    background: $secondary;
    color: $positive;
    white-space: nowrap;
}

:deep(.menu-fix) {
    max-height: 100% !important;
}

.q-card {
    max-height: inherit;
    box-shadow: none;
}

.q-card :deep(.q-card__section) {
    max-height: inherit;

    &:nth-of-type(1) {
        min-width: 340px;
    }

    ol {
        list-style-position: outside;
        list-style-type: disc;
        column-count: auto;
        column-fill: auto;
        height: 100%;
    }

    a {
        text-decoration: none;
    }
}
