@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.q-btn {
    border-radius: $button-border-radius;
    padding: 0;
    min-height: 16px;
    transition: $main-transition;

    :deep &__content {
        padding: 4px 0;
    }
}

.no-padding :deep(.q-btn__content) {
    padding: 0 !important;
}

.q-btn :deep(.q-btn__wrapper:before) {
    border: inherit;
    border-radius: inherit;
}

.q-btn.thin :deep(.q-btn__wrapper:before) {
    border: 1px solid $grey-13;
    border-radius: inherit;
}

.q-btn.gradient {
    border: none;
    background: $lime !important;
}

.q-btn.gradient :deep(.q-btn__wrapper:before) {
    border: 0px solid $grey-13;
}

.q-btn :deep(.q-btn__wrapper) {
    min-height: 1em;
    padding: 0;
}

.btn__label {
    @media screen and (max-width: $breakpoint-mobile) {
        padding-left: 0;
    }
}
